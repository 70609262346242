import { includes, isEmpty } from 'lodash';

import { BaseConnector } from '../../utils/connectors/base-connector';
import { getDataExplorationApi } from '../../utils/connectors/api-url';
import { UniverseEdgeTypeName, UniverseId, UniverseType } from 'src/exploration/model/universe';
import { ProgressMonitor, PropertySorter, SubProgressMonitor } from 'src/components/basic';
import {
    FullOntology,
    OntologyId,
    OntologyLinkType,
    OntologyMetaProperty,
    OntologyObjectType,
    OntologyOperationType,
    OntologyProperty,
} from '../universes/ontology/types';
import {
    AddEditOntology,
    Ontology,
    DetailedOntology,
    GetOntologySchemaDTO,
    GetOntologyStyleDTO,
    PutOntologyStyleDTO,
    ListOntologies,
    UniverseKBFeedingData,
    UniverseFeedLog,
    UniverseFeedLogStatus,
} from '../models/dtoApi';
import {
    FormDisplayTemplate,
    FormDisplayTemplateKey,
    FormDisplayTemplates,
} from '../../exploration/model/form-display-template';
import { formatUniverseWithSettings, mapFormDisplayTemplates } from '../../exploration/utils/connector/mappers';
import {
    ExecuteOntologyFeedSourcesRequest,
    OntologyFeedSource,
    OntologyFeedSourceExecuteResponse,
    OntologyFeedSources,
    OntologyFeedSourceValidationResultResponse,
} from '../models/feed-sources';
import { mapDetailedOntology, mapObjectsWithStyle, mapOntology, mapOntologyObjectsStyle, mapUniverseFeedLog, mapUniverseKBFeedingData } from './mappers';
import { RawDetailedOntology, RawOntology, RawUniverseFeedLog, RawUniverseKBFeedingData } from '../models/raws';
import { ConnectorRequestInit, RangeResponse } from '../../utils/connector';

const EMPTY_ERROR = {};

export interface GetUniversesKbFeedingLogsParams {
    top: number;
    skip: number;
    sorting?: PropertySorter;
    universeIds?: UniverseId[];
    statuses?: UniverseFeedLogStatus[];
    dateFrom?: Date;
    dateTo?: Date;
}

class OntologiesConnector extends BaseConnector {
    private static instance: OntologiesConnector;

    static getInstance(): OntologiesConnector {
        if (!OntologiesConnector.instance) {
            OntologiesConnector.instance = new OntologiesConnector('settings.ontologies', getDataExplorationApi());
        }

        return OntologiesConnector.instance;
    }


    async getOntology(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<DetailedOntology> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}`;

        const response: RawDetailedOntology = await this.request(
            url,
            {
                verifyJSONResponse: true,
            },
            progressMonitor,
        );
        const ret = mapDetailedOntology(response);

        return ret;
    }

    async getOntologySchema(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<GetOntologySchemaDTO> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema`;

        const result: GetOntologySchemaDTO = await this.request(
            url,
            {
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        return result;
    }

    async getOntologyStyle(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<GetOntologyStyleDTO> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/style`;

        const result: GetOntologyStyleDTO = await this.request(
            url,
            {
                method: 'GET',
                verifyJSONResponse: true,
            },
            progressMonitor,
        );

        return result;
    }

    async getFullOntology(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<FullOntology> {
        const [ontology, ontologySchema, ontologyStyle] = await Promise.all([
            this.getOntology(ontologyId, progressMonitor),
            this.getOntologySchema(ontologyId, progressMonitor),
            this.getOntologyStyle(ontologyId, progressMonitor),
        ]);

        const objectStyle = mapOntologyObjectsStyle(ontologySchema.objectTypes, ontologyStyle.objectTypes);
        const linksStyle = mapOntologyObjectsStyle(ontologySchema.linkTypes, ontologyStyle.linkTypes);

        const ret: FullOntology = {
            id: ontology.id,
            name: ontology.name,
            description: ontology.description,
            universeIds: ontology.universeIds,
            lastPublishedBy: ontology.lastPublishedBy,
            lastPublishedDate: ontology.lastPublishedDate,
            objectTypes: mapObjectsWithStyle(ontologySchema.objectTypes, objectStyle),
            linkTypes: mapObjectsWithStyle(ontologySchema.linkTypes, linksStyle),
            metaProperties: ontologySchema.metaProperties,
            fullStyle: {
                objectTypes: objectStyle,
                linkTypes: linksStyle,
            },
        };

        return ret;
    }

    async editOntologyStyle(
        ontologyId: OntologyId,
        newStyle: PutOntologyStyleDTO,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/style`;

        const options = {
            method: 'PUT',
            json: newStyle,
        };

        await this.request(url, options, progressMonitor);
    }

    async getOntologies(
        withKbFeedingData: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<ListOntologies> {
        const url = '/ontologies';
        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const sub1 = new SubProgressMonitor(progressMonitor, 1);
        const response: RawOntology[] = await this.request(url, options, sub1);
        const ontologies = response.map(mapOntology);

        const ret: ListOntologies = {
            ontologies,
        };

        if (withKbFeedingData) {
            ret.kbFeedingDataByOntologyId = await this.buildKbFeedingDataByOntologyId(ontologies, progressMonitor);
        }

        return ret;
    }


    async getUniverseKbFeedingData(universeId: UniverseId, ontologies: Ontology[], progressMonitor = ProgressMonitor.empty()) {
        const url = `/universes/${encodeURIComponent(universeId)}/feed-monitoring`;
        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const response: RawUniverseKBFeedingData = await this.request(url, options, progressMonitor);
        const ret = mapUniverseKBFeedingData(response, universeId);
        const ontology = ontologies.find(ontology => includes(ontology.universeIds, universeId));
        // API is not returning this data for now. Temporary => use ontology information.
        ret.firstSynchronizationDate = ontology?.createdDate;
        ret.order = [(ontology ? ontologies.indexOf(ontology) + 1 : 0), ontologies.length];

        return ret;
    }

    async getUniversesKbFeedingLogs(params: GetUniversesKbFeedingLogsParams, progressMonitor = ProgressMonitor.empty()) {
        const url = '/universes/feed-logs';
        let sortingDirection : 'Ascending' | 'Descending' | undefined;
        if (params.sorting) {
            sortingDirection = params.sorting.order === 'ascending' ? 'Ascending' : 'Descending';
        }

        const options: ConnectorRequestInit = {
            method: 'GET',
            verifyJSONResponse: true,
            params: {
                top: params.top,
                skip: params.skip,
                sortingColumn: params.sorting?.propertyName,
                sortingDirection,
                dateFrom: params.dateFrom?.toISOString(),
                dataTo: params.dateTo?.toISOString(),
                universeIds: params.universeIds,
                statuses: params.statuses,
            },
        };

        const result = await this.requestResults<RawUniverseFeedLog>(url, 'universeFeedLogs', options, progressMonitor);

        const ret: RangeResponse<UniverseFeedLog> = {
            ...result,
            data: result.data.map(mapUniverseFeedLog),
        };

        return ret;
    }

    private async buildKbFeedingDataByOntologyId(ontologies: Ontology[], progressMonitor: ProgressMonitor) {
        const ret: Record<OntologyId, UniverseKBFeedingData> = {};
        const promises = ontologies
            .filter(ontology => ontology.universeIds.length)
            .map(async (ontology) => {
                const universeId = ontology.universeIds[0];

                const sub1 = new SubProgressMonitor(progressMonitor, 1);

                const universeKbFeedingData = await this.getUniverseKbFeedingData(universeId, ontologies, sub1);
                ret[ontology.id] = universeKbFeedingData;
            });

        await Promise.allSettled(promises);

        return ret;
    }

    async publishOntology(
        ontologyId: OntologyId,
        type: 'Publish',
        comment: string,
        force: boolean,
        ensure_version?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}`;
        const options = {
            method: 'POST',
            json: { comment },
            params: {
                type,
                force,
                ensure_version,
            },
            verifyJSONResponse: false,
        };
        await this.request(url, options, progressMonitor);
    }

    async executeOntology(
        ontologyId: OntologyId,
        operationType: OntologyOperationType,
        body?: Record<string, any>,
        force?: boolean,
        ensureVersion?: number,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}`;
        const options = {
            method: 'POST',
            params: {
                type: operationType,
                force,
                ensure_version: ensureVersion,
            },
            json: body,
        };

        await this.request(url, options, progressMonitor);
    }

    async addOntology(
        ontologyName: string,
        ontologyDescription?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<OntologyId> {
        const url = '/ontologies';
        const options = {
            method: 'POST',
            json: {
                name: ontologyName,
                description: ontologyDescription,
            },
        };
        const { id } = await this.request(url, options, progressMonitor);

        return id;
    }

    async deleteOntology(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async addOntologyMetaProperty(
        metaPropertyToAdd: Omit<OntologyMetaProperty, 'name'>,
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema/meta-properties`;
        const options = {
            method: 'POST',
            json: metaPropertyToAdd,
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteMetaProperty(
        name: string,
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema/meta-properties/${encodeURIComponent(name)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async editMetaProperty(
        ontologyId: OntologyId,
        name: string,
        metaPropertyToEdit: {
            displayName: string;
            clientMetadata?: Record<string, any>;
            constraint?: OntologyMetaProperty['constraint'];
            description?: string;
            pathDefinition?: {
                separator: string;
            };
        },
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema/meta-properties/${encodeURIComponent(name)}`;
        const options = {
            method: 'PUT',
            json: metaPropertyToEdit,
        };
        await this.request(url, options, progressMonitor);
    }

    async editOntology(
        editUniversePayload: { name?: string; description?: string },
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}`;
        const options = {
            method: 'PUT',
            json: editUniversePayload,
        };
        await this.request(url, options, progressMonitor);
    }

    async createObject(
        displayName: string,
        ontologyId: OntologyId,
        properties: OntologyProperty[] = [],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<OntologyObjectType> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema/object-types`;
        const options = {
            method: 'POST',
            json: { displayName, properties },
        };
        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async deleteObject(
        ontologyId: OntologyId,
        name: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema/object-types/${encodeURIComponent(name)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }

    async createEdge(
        ontologyId: OntologyId,
        displayName: string,
        sourceObjectName: string,
        destinationObjectName: string,
        properties: OntologyProperty[] = [],
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<OntologyLinkType> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema/link-types`;
        const options = {
            method: 'POST',
            json: {
                sourceObjectName,
                destinationObjectName,
                displayName,
                properties,
            },
        };
        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }

    async editOntologyObject(
        editOntology: AddEditOntology,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(editOntology.ontologyId)}/schema/object-types/${encodeURIComponent(editOntology.name)}`;
        const options = {
            method: 'PUT',
            json: {
                displayName: editOntology.newDisplayName,
                properties: editOntology.newProperties,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async editOntologyEdge(
        editOntology: AddEditOntology,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(editOntology.ontologyId)}/schema/link-types/${encodeURIComponent(editOntology.name)}`;
        const options = {
            method: 'PUT',
            json: {
                displayName: editOntology.newDisplayName,
                properties: editOntology.newProperties,
            },
        };
        await this.request(url, options, progressMonitor);
    }

    async deleteEdge(
        name: UniverseEdgeTypeName,
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/schema/link-types/${encodeURIComponent(name)}`;
        const options = {
            method: 'DELETE',
        };
        await this.request(url, options, progressMonitor);
    }


    async getForms(
        ontologyId: OntologyId,
        objectOrRelation: 'object' | 'relation',
        objectTypeName: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<FormDisplayTemplates> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/display-templates/${encodeURIComponent(objectOrRelation)}-types/${encodeURIComponent(objectTypeName)}`;

        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        return mapFormDisplayTemplates(result);
    }

    async putForm(
        ontologyId: OntologyId,
        objectOrRelation: 'object' | 'relation',
        objectTypeName: string,
        formKey: FormDisplayTemplateKey,
        form: FormDisplayTemplate,
        isDefault: boolean,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/display-templates/${encodeURIComponent(objectOrRelation)}-types/${encodeURIComponent(objectTypeName)}/${encodeURIComponent(formKey)}`;

        const options = {
            method: 'PUT',
            json: {
                isDefault,
                displayTemplate: form,
            },
        };

        await this.request(url, options, progressMonitor);
    }

    async deleteForm(
        ontologyId: OntologyId,
        objectOrRelation: 'object' | 'relation',
        objectTypeName: string,
        formKey: FormDisplayTemplateKey,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/display-templates/${encodeURIComponent(objectOrRelation)}-types/${encodeURIComponent(objectTypeName)}/${encodeURIComponent(formKey)}`;

        const options = {
            method: 'DELETE',
        };

        await this.request(url, options, progressMonitor);
    }

    async validateFeedSource(
        ontologyId: OntologyId,
        objectOrRelation: 'object' | 'relation',
        objectTypeName: string,
        feedSource: OntologyFeedSource,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<OntologyFeedSourceValidationResultResponse> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/feed-sources/${encodeURIComponent(objectOrRelation)}-types/${encodeURIComponent(objectTypeName)}/validate`;
        const options = {
            method: 'POST',
            json: feedSource,
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        if (!result) {
            return EMPTY_ERROR;
        }

        if (isEmpty(result.parseErrors)) {
            result.parseErrors = undefined;
        }

        return result;
    }

    async getFeedSources(
        ontologyId: OntologyId,
        objectOrRelation: 'object' | 'relation',
        objectTypeName: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<OntologyFeedSources> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/feed-sources/${encodeURIComponent(objectOrRelation)}-types/${encodeURIComponent(objectTypeName)}`;

        const options = {
            method: 'GET',
            verifyJSONResponse: true,
        };

        const result = await this.request(url, options, progressMonitor);

        if (!result.feedSources) {
            result.feedSources = undefined;
        }

        return result;
    }

    async putFeedSources(
        ontologyId: OntologyId,
        objectOrRelation: 'object' | 'relation',
        objectTypeName: string,
        feedSources: OntologyFeedSources,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/feed-sources/${encodeURIComponent(objectOrRelation)}-types/${encodeURIComponent(objectTypeName)}`;

        const options = {
            method: 'PUT',
            verifyJSONResponse: true,
            json: feedSources,
        };

        await this.request(url, options, progressMonitor);
    }

    async executeFeedSources(
        ontologyId: OntologyId,
        objectOrRelation: 'object' | 'relation',
        objectTypeName: string,
        feedSourceRequest: ExecuteOntologyFeedSourcesRequest,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<OntologyFeedSourceExecuteResponse> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/feed-sources/${encodeURIComponent(objectOrRelation)}-types/${encodeURIComponent(objectTypeName)}/execute`;

        const options = {
            method: 'POST',
            verifyJSONResponse: true,
            json: feedSourceRequest,
        };

        const ret = await this.request(url, options, progressMonitor);

        return ret;
    }


    async createUniverse(
        name: string,
        description?: string,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<UniverseId> {
        const url = '/universes';
        const options = {
            method: 'POST',
            params: {
                name,
                description,
            },
        };
        const result = await this.request(url, options, progressMonitor);

        return result.id;
    }

    async getUniverse(
        universeId: UniverseId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<UniverseType> {
        const url = `/universes/${encodeURIComponent(universeId)}`;

        const result = await this.request(url, {
            verifyJSONResponse: true,
        }, progressMonitor);

        const ret = formatUniverseWithSettings(result);

        return ret;
    }

    async resetUniverse(universeId: UniverseId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = `/universes/${encodeURIComponent(universeId)}/clear`;
        const options = {
            method: 'POST',
        };
        await this.request(url, options, progressMonitor);
    }

    async resetSync(universeId: UniverseId, progressMonitor: ProgressMonitor = ProgressMonitor.empty()): Promise<void> {
        const url = `/universes/${encodeURIComponent(universeId)}/reset-synchronization`;
        const options = {
            method: 'POST',
        };
        await this.request(url, options, progressMonitor);
    }

    async cloneOntology(
        ontologyId: OntologyId,
        progressMonitor: ProgressMonitor = ProgressMonitor.empty(),
    ): Promise<void> {
        const url = `/ontologies/${encodeURIComponent(ontologyId)}/clone`;
        const options = {
            method: 'POST',
        };

        await this.request(url, options, progressMonitor);
    }
}

export default OntologiesConnector.getInstance();
